import React, { Component } from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout';
import NotFoundComponent from "../components/notFoundComponent";
import Helmet from "../components/helmet.component"
import { setSmoIcon } from '../utils/common';

const replaceImageStyle = (
  content
) => {
  return content && content.replace(/<img style="max-width:100%;width:100px"/g, '<img ')
}
export default class CountryThemeSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wuUserLoginStaus: false
    }
  }

  componentDidMount() {
    setSmoIcon(this.props.pageContext);
  }

  render() {
    const  {
      // pageContent,
      // seoMetaData,
      // slug,
      language,
      headerContent,
      footerContent,
      // switchContent,
      criticalCss,
      // criticalMainCss,
      mainCssContent,
      isRtl
    } = this.props.pageContext
    const { countryTheme } = this.props.data
    const languageWiseData = 
            countryTheme.languageCountryThemeSetting 
            && countryTheme.languageCountryThemeSetting.languageCountryThemeSettings
            && countryTheme.languageCountryThemeSetting.languageCountryThemeSettings.find((langData) => {
              return  langData.language === language
            });

    // const headerContent = replaceImageStyle(this.props.pageContext.headerContent);
    // const footerContent = replaceImageStyle(this.props.pageContext.footerContent);
    return (
      <>
        <div className="entry-content" dangerouslySetInnerHTML={{ __html: `<style>${criticalCss? criticalCss : '' }</style>` }} />
        <div className="entry-content" dangerouslySetInnerHTML={{ __html: `<style>${mainCssContent}</style>` }} />
        <Helmet pageContext={this.props.pageContext} isEvergageEnabled={ false } />
          
        <Layout pageContext={this.props.pageContext}>
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: headerContent }} />
          < NotFoundComponent countryThemeSettingsProps = {
                languageWiseData ?
                  { "countryThemeSettings": languageWiseData }: countryTheme
                } isRtl={isRtl}/>
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: footerContent }} />

        </Layout>
      </>  
    )
  }
}
export const query = graphql`
  query countryThemeSetting($id: String!) {
    countryTheme: wpCountryThemeSetting(id: {
      eq: $id
    }) {
      id
      countryThemeSettings {
        page404 {
          title
          link {
            target
            title
            url
          }
          menuItems {
            link {
              title
              target
              url
            }
            fieldGroupName
          }
          subtitle
          menuTitle
          fieldGroupName
        }
      }
      title
      contentType {
        node {
          id
        }
      }
      languageCountryThemeSetting {
        languageCountryThemeSettings {
          language
          page404 {
            title
            link {
              target
              title
              url
            }
            menuItems {
              link {
                title
                target
                url
              }
              fieldGroupName
            }
            subtitle
            menuTitle
            fieldGroupName
          }
        }
      }
    }
  }
`
