import React from 'react'
import { withPrefix } from "gatsby"

class NotFoundComponent extends React.Component {
    render() {
        const properties = this.props.countryThemeSettingsProps;
        const isRtl = this.props.isRtl
        function MenuItems(props) { 
            const content = props.menuItem && props.menuItem.map((menuItem, index) =>
                <a href={menuItem.link.url} 
                    class={`font-body-sm text-wu-dark ${index === 0 && isRtl ? '': (isRtl ? 'mar-md-lt': 'mar-md-rt')}`}
                >               
                    <u>
                        <small>
                            {menuItem.link.title}
                        </small>
                    </u>
                </a>
            );
            return (
                <div>
                    {content}
                </div>
            );
        }


        const menuItems = properties.countryThemeSettings.page404.menuItems;
  
        return (
            <div>
                <main class="container-fuild bk-action-light pad-xl-vert position-relative">
                    <div class="position-absolute w-50 h-100 b-404-page-pattern">
                        <div class="position-absolute w-100 h-100"></div>
                    </div>
                    <div class="container pad-xxxl-vert">
                        <div class="row">
                            <div class="col col-16 col-M-12 col-L-8 pad-xxxl-vert b-404-page-text">
                                <div class="font-heading-h1 mar-lg-bm font-weight-bold b-title-404">
                                    {properties?.countryThemeSettings?.page404?.title}
                                </div>
                                <div class="font-body-reg  mar-xs-bm">
                                    <strong>{properties?.countryThemeSettings?.page404?.subtitle}</strong>
                                </div>
                                <a href={properties?.countryThemeSettings?.page404?.link?.url} class="wu-btn wu-btn--dark wu-btn--round text-action-light wu-btn--action-light-ghost mar-lg-bm">{properties?.countryThemeSettings?.page404?.link?.title}</a>
                                <div class="wu-dividers wu-dividers--solid mar-xs-bm"></div>
                                <div class="font-body-sm  mar-xs-bm">
                                    {properties?.countryThemeSettings?.page404?.menuTitle}
                                </div>
                                <div class="d-flex">

                                    <MenuItems menuItem={menuItems} />
                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </div>

        );
    }
}
export default NotFoundComponent